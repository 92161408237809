/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from 'src/components/GTM';

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html
            xmlns="http://www.w3.org/1999/xhtml"
            fakexmlnsfb="http://www.facebook.com/2008/fbml"
            lang="cs"
          />
          <body id="produkty" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <title>
            NEUTROGENA® - kosmetyki z maliną nordycką, które 3x poprawiają
            poziom nawilżenia skóry*
          </title>
          <meta
            name="description"
            content="Wypróbuj linię kosmetyków NEUTROGENA® z superowocem – maliną nordycką, dzięki którym Twoja skóra będzie miękka i odżywiona dzień po dniu."
          />
          <meta
            name="keywords"
            content="malina nordycka, malina moroszka, malina norweska, piękna skóra, balsam do ciała, krem do stóp, emulsja do ciała, krem do rąk, sztyft do ust, kosmetyki nawilżające, superowoc, Neutrogena, szminka do ust, formuła norweska, nawilżanie, nawilżenie, sucha skóra, Norwegia"
          />
          <meta property="og:title" content="NEUTROGENA®" />
          <meta property="og:type" content="product" />
          <meta property="og:url" content="http://www.malinanordycka.pl" />
          <meta
            property="og:image"
            content="http://www.malinanordycka.pl/img/fb/neutrogena-200x200.jpg"
          />
          <meta property="og:site_name" content="Neutrogena" />
          <meta property="fb:admins" content="100000071451321" />
          <meta
            property="og:description"
            content="NEUTROGENA® z maliną nordycką, piękna i odżywiona skóra dzień po dniu."
          />
          <meta httpEquiv="Expires" content="0" />
          <meta
            httpEquiv="Cache-Control"
            content="no-store, no-cache, must-revalidate"
          />
          <meta httpEquiv="Cache-Control" content="post-check=0, pre-check=0" />
          <meta httpEquiv="Pragma" content="no-cache" />
          <link
            id="page_favicon"
            href="/malinanordycka/img/favicon.ico"
            rel="icon"
            type="image/x-icon"
          />
          <link
            href="/malinanordycka/css/style.css"
            rel="stylesheet"
            type="text/css"
          />
          <script src="/themes/neutrogenacz/js/jquery-3.5.1.js" />
          <script src="/themes/neutrogenacz/js/jquery-migrate-3.3.0.js" />
          <script type="text/javascript" src="/themes/neutrogenacz/js/jquery-migrate-1.4.1.js" />

          <script
            type="text/javascript"
            innerHTML='
<!--//--><![CDATA[//><!--
function OptanonWrapper() { Optanon.InsertHtml(&apos;<div id="like"><script type="text/javascript" dangerouslySetInnerHTML={{ __html:"\n//<![CDATA[\ndocument.write(\"<div class="fb-like" style="margin-top:22px" data-href="http://poligon.safehost.pl/neutrogena" data-send="false" data-layout="button_count" data-width="450" data-show-faces="true"></div>\");\n//]]>\n"}}/></div>&apos;, &apos;like_8&apos;, null, null, &apos;4&apos;); 
Optanon.InsertHtml(&apos;<div class="like1"><script type="text/javascript" dangerouslySetInnerHTML={{ __html:"\n//<![CDATA[\ndocument.write(\"<div class="fb-like" data-href="http://poligon.safehost.pl/neutrogena/produkty-do-ciala.php" data-send="false" data-layout="button_count" data-width="450" data-show-faces="true"></div>\");\n//]]>\n"}}/></div>&apos;, &apos;like_9&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like1"><script type="text/javascript" dangerouslySetInnerHTML={{ __html:"\n//<![CDATA[\ndocument.write(\"<div class="fb-like" data-href="http://poligon.safehost.pl/neutrogena/produkty-do-dloni-i-stop.php" data-send="false" data-layout="button_count" data-width="450" data-show-faces="true"></div>\");\n//]]>\n"}}/></div>&apos;, &apos;like_10&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like1"><script type="text/javascript" dangerouslySetInnerHTML={{__html:"\n//<![CDATA[\ndocument.write(\"<div class="fb-like" data-href="http://poligon.safehost.pl/neutrogena/produkty-do-ust.php" data-send="false" data-layout="button_count" data-width="450" data-show-faces="true"></div>\");\n//]]>\n"}}/></div>&apos;, &apos;like_11&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like1a"><iframe src="//www.facebook.com/plugins/like.php?locale=cs_CZ&href=http%3A%2F%2Fpoligon.safehost.pl%2Fneutrogena%2Fneutrogena-formula-norweska-odzywcza-emulsja-do-ciala.php&send=false&layout=button_count&width=450&show_faces=false&action=like&colorscheme=light&font&height=21&appId=281005911975464" scrolling="no" frameBorder="0" style={{border: "none",overflow: "hidden",width: "450px",height: "21px" }} allowTransparency="true"/></div>&apos;, &apos;like_12&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like2a"><iframe src="//www.facebook.com/plugins/like.php?locale=cs_CZ&href=http%3A%2F%2Fpoligon.safehost.pl%2Fneutrogena%2Fneutrogena-formula-norweska-odzywczy-balsam-do-ciala.php&send=false&layout=button_count&width=450&show_faces=false&action=like&colorscheme=light&font&height=21&appId=281005911975464" scrolling="no" frameBorder="0" style={{border: "none",overflow: "hidden",width: "450px",height: "21px"}} allowTransparency="true"/></div>&apos;, &apos;like_13&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like1a"><iframe src="//www.facebook.com/plugins/like.php?locale=cs_CZ&href=http%3A%2F%2Fpoligon.safehost.pl%2Fneutrogena%2Fneutrogena-formula-norweska-odzywczy-krem-do-rak.php&send=false&layout=button_count&width=450&show_faces=false&action=like&colorscheme=light&font&height=21&appId=281005911975464" scrolling="no" frameBorder="0" style={{border: "none",overflow: "hidden",width: "450px",height: "21px"}} allowTransparency="true"/></div>&apos;, &apos;like_14&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like2a"><iframe src="//www.facebook.com/plugins/like.php?locale=cs_CZ&href=http%3A%2F%2Fpoligon.safehost.pl%2Fneutrogena%2Fneutrogena-formula-norweska-odzywczy-krem-do-stop.php&send=false&layout=button_count&width=450&show_faces=false&action=like&colorscheme=light&font&height=21&appId=281005911975464" scrolling="no" frameBorder="0" style={{border: "none",overflow: "hidden",width: "450px",height: "21px"}} allowTransparency="true"/></div>&apos;, &apos;like_15&apos;, null, null, &apos;4&apos;);
Optanon.InsertHtml(&apos;<div class="like1a"><iframe src="//www.facebook.com/plugins/like.php?locale=cs_CZ&href=http%3A%2F%2Fpoligon.safehost.pl%2Fneutrogena%2Fneutrogena-formula-norweska-odzywczy-sztyft-do-ust.php&send=false&layout=button_count&width=450&show_faces=false&action=like&colorscheme=light&font&height=21&appId=281005911975464" scrolling="no" frameBorder="0" style={{border: "none",overflow: "hidden",width: "450px",height: "21px"}} allowTransparency="true"/></div>&apos;, &apos;like_16&apos;, null, null, &apos;4&apos;);}
//--><!]]>
'
          />
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
