import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\n//<![CDATA[\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/cs_CZ/all.js#xfbml=1&appId=289254391188854\";\nfjs.parentNode.insertBefore(js, fjs);\n//]]>\n}(document, 'script', 'facebook-jssdk'));\n//<![CDATA[\nvar licznik=1;\n$(document).ready(function(){\n$('#do-ciala').click(function(){\n$('#pop1').fadeIn();\n$('#pop2').hide();\n$('#pop3').hide();\n})\n$('#do-dloni-i-stop').click(function(){\n$('#pop2').fadeIn();\n$('#pop1').hide();\n$('#pop3').hide();\n})\n$('#do-ust').click(function(){\n$('#pop3').fadeIn();\n$('#pop1').hide();\n$('#pop2').hide();\n})\n$('.closer').click(function(){\n$('#pop1').fadeOut();\n$('#pop2').fadeOut();\n$('#pop3').fadeOut();\n})\n$('.popnext').click(function(){\nlicznik++;\nif (licznik>3) licznik=1;\nif (licznik==1){\n$('#pop1').fadeIn();\n$('#pop2').hide();\n$('#pop3').hide();\n}\nif (licznik==2){\n$('#pop2').fadeIn();\n$('#pop1').hide();\n$('#pop3').hide();\n}\nif (licznik==3){\n$('#pop3').fadeIn();\n$('#pop1').hide();\n$('#pop2').hide();\n}\n})\n$('.popprev').click(function(){\nlicznik--;\nif (licznik==0) licznik=3;\nif (licznik==1){\n$('#pop1').fadeIn();\n$('#pop2').hide();\n$('#pop3').hide();\n}\nif (licznik==2){\n$('#pop2').fadeIn();\n$('#pop1').hide();\n$('#pop3').hide();\n}\nif (licznik==3){\n$('#pop3').fadeIn();\n$('#pop1').hide();\n$('#pop2').hide();\n}\n})\n$('.popclose').click(function(){\n$('#blogerki_pop').hide();\n})\n})\n//]]>\n"
          }}
        />
        <div
          style={{
            width: "950px",
            margin: "0 auto",
            background: "#fff url(/malinanordycka/img/bgprod1.jpg) center 55px no-repeat",
            "padding-bottom": "20px"
          }}
        >
          <div id="header">
            <div id="menu">
              <a href="/malinanordycka/index">domovská stránka</a>
              <a
                href="/malinanordycka/produkty"
                style={{
                  "font-weight": "bold"
                }}
              >
                výrobky
              </a>
              <a href="/malinanordycka/malina-nordycka">
                ostružiník moruška
              </a>
              <div id="onprodukty">
                <img
                  src="/malinanordycka/img/on.gif"
                  width="5"
                  height="5"
                  alt
                />
              </div>
              <div className="lo">
                <a href="/malinanordycka/index">
                  <img
                    src="/malinanordycka/img/none.gif"
                    width="200"
                    height="55"
                    alt
                  />
                </a>
              </div>
            </div>
          </div>
          <div id="top">
            <div className="p1">
              Historie značky NEUTROGENA<sup>®</sup> sahá do roku 1930.
              Společnost byla původně důležitým dodavatelem výrobků péče o
              pokožku pro salóny krásy, ve 40. letech 20. století však začala
              vyrábět své vlastní výrobky nejvyšší kvality a&nbsp;distribuovat
              je na maloobchodním trhu.
              <br />
              <br />
              Už na počátku bylo hlavní prioritou výrobků NEUTROGENA<sup>
                ®
              </sup>{" "}
              účinné působení. Díky spolupráci s&nbsp;dermatology
              a&nbsp;receptuře založené na jedinečném složení aktivních látek
              byly vytvořeny výrobky poskytující skvělou péči o ženskou pokožku.
              <br />
            </div>
            <div className="p1">
              Nabídka značky zahrnuje kosmetické výrobky určené i pro
              nejnáročnější typy pokožky a uspokojuje potřeby lidí ve více než
              70 zemích světa.
              <br />
              <br />
              Součástí nabídky je také ucelená řady výrobků denní péče o pokožku
              NEUTROGENA<sup>®</sup> Norská receptura Nordic Berry s
              ostružiníkem moruškou vyvinutá ve spolupráci s dermatology.
              <br />
              <br />
              Kombinace unikátní Norské receptury a&nbsp;mimořádných vlastností
              ostružiníku morušky obsažená ve výrobcích NEUTROGENA<sup>
                ®
              </sup>{" "}
              Nordic Berry spolu s přirozenými procesy pokožky až trojnásobně
              zvyšuje stupeň hydratace pokožky* a&nbsp;intenzivně ji vyživuje.
              <br />
            </div>
            <div className="p1">
              Řada Nordic Berry obsahuje vyživující tělová mléka, krémy na ruce,
              krémy na nohy a&nbsp;balzám na rty. Veškeré výrobky jsou jemně
              parfémované s&nbsp;lehkou, rychle se vstřebávající texturou.
              <br />
              <br />
              <span
                style={{
                  "font-size": "10px"
                }}
              >
                *index hladiny hydratace 7 hodin po aplikaci produktu
              </span>
            </div>
            <div className="clr" />
          </div>
          <div id="like_8"></div>
          <div id="cols">
            <div className="col">
              <img
                src="/malinanordycka/img/prod1.jpg"
                style={{
                  float: "right"
                }}
                width="110"
                height="155"
                alt
              />
              <h1>Péče</h1>
              <h2>o tělo</h2>
              <p>
                Pečujte o své tělo. Díky výživnému tělovému balzámu a&nbsp;mléku
                NEUTROGENA<sup>®</sup> Norská receptura Nordic Berry bude vaše
                pokožka den za dnem hebká a&nbsp;vyživená.
              </p>
              <div id="like_9"></div>
              <div className="fr">
                <a id="do-ciala">
                  <img
                    src="/malinanordycka/img/przeczytaj.gif"
                    style={{
                      float: "right"
                    }}
                    width="121"
                    height="49"
                    alt
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <img
                src="/malinanordycka/img/prod2.jpg"
                style={{
                  float: "right"
                }}
                width="68"
                height="153"
                alt
              />
              <h1>Péče</h1>
              <h2>o ruce a&nbsp;nohy</h2>
              <p>
                Nezapomínejte, že vaše ruce a&nbsp;nohy vás reprezentují.
                Pečujte o ně. Díky výrobkům NEUTROGENA<sup>®</sup> Norská
                receptura Nordic Berry budou vaše ruce a&nbsp;nohy hladké
                a&nbsp;správně hydratované.
              </p>
              <div id="like_10"></div>
              <div className="fr">
                <a id="do-dloni-i-stop">
                  <img
                    src="/malinanordycka/img/przeczytaj.gif"
                    style={{
                      float: "right"
                    }}
                    width="121"
                    height="49"
                    alt
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <img
                src="/malinanordycka/img/prod3.jpg"
                style={{
                  float: "right"
                }}
                width="77"
                height="144"
                alt
              />
              <h1>Péče</h1>
              <h2>o rty</h2>
              <p>
                Výživný balzám na rty NEUTROGENA<sup>®</sup> Norská receptura
                Nordic Berry přinese okamžitou úlevu suchým a&nbsp;popraskaným
                rtům. Vaše rty budou opět hladké a&nbsp;jemné.{" "}
              </p>
              <div id="like_11"></div>
              <div className="fr">
                <a id="do-ust">
                  <img
                    src="/malinanordycka/img/przeczytaj.gif"
                    style={{
                      float: "right"
                    }}
                    width="121"
                    height="49"
                    alt
                  />
                </a>
              </div>
            </div>
            <div className="clr" />
          </div>
          <div id="pop1">
            <div className="bgprod-big">
              <div className="closer" />
              <div
                className="pr0"
                style={{
                  "font-family": "arial",
                  "font-size": "15px",
                  "font-weight": "bold"
                }}
              >
                Péče o tělo
              </div>
              <div className="popprev">
                <img
                  src="/malinanordycka/img/prev.png"
                  width="29"
                  height="57"
                  alt
                />
              </div>
              <div className="popnext">
                <img
                  src="/malinanordycka/img/next.png"
                  width="29"
                  height="57"
                  alt
                />
              </div>
              <div className="pr1">
                <div
                  className="r1"
                  style={{
                    "font-weight": "bold"
                  }}
                >
                  NEUTROGENA<sup>®</sup> Norská receptura
                </div>
                <div className="r2">Výživné tělové mléko Nordic Berry</div>
                <ul>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Podrobnější informace:
                    </span>
                    <br />
                    určeno pro citlivou a&nbsp;suchou pokožku{" "}
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Návod k použití:
                    </span>
                    <br />
                    aplikujte denně na celé tělo, nejlépe po sprchování či po
                    koupeli
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Objem balení:
                    </span>
                    <br />
                    250 ml; a&nbsp;400 ml
                  </li>
                </ul>
                <div className="mldociala">
                  <img
                    src="/malinanordycka/img/mleczko-do-ciala.png"
                    width="162"
                    height="260"
                    alt
                  />
                </div>
                <div id="like_12"></div>
              </div>
              <div className="pr2">
                <div
                  className="r1"
                  style={{
                    "font-weight": "bold"
                  }}
                >
                  NEUTROGENA<sup>®</sup> Norská receptura
                </div>
                <div className="r2">Výživný tělový balzám Nordic Berry</div>
                <ul>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Podrobnější informace:
                    </span>
                    <br />
                    určeno pro citlivou a&nbsp;suchou pokožku; obsahuje vitamín
                    E
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Návod k použití:
                    </span>
                    <br />
                    aplikujte denně na celé tělo, nejlépe po sprchování či po
                    koupeli
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Objem balení:
                    </span>
                    <br />
                    200 ml
                  </li>
                </ul>
                <div className="balociala">
                  <img
                    src="/malinanordycka/img/baldociala.png"
                    width="191"
                    height="140"
                    alt
                  />
                </div>
                <div id="like_13"></div>
              </div>
            </div>
          </div>
          <div id="pop2">
            <div className="bgprod-big">
              <div className="closer" />
              <div
                className="pr0"
                style={{
                  "font-family": "arial",
                  "font-size": "15px",
                  "font-weight": "bold"
                }}
              >
                Péče o ruce
              </div>
              <div className="popprev">
                <img
                  src="/malinanordycka/img/prev.png"
                  width="29"
                  height="57"
                  alt
                />
              </div>
              <div className="popnext">
                <img
                  src="/malinanordycka/img/next.png"
                  width="29"
                  height="57"
                  alt
                />
              </div>
              <div className="pr1">
                <div
                  className="r1"
                  style={{
                    "font-weight": "bold"
                  }}
                >
                  NEUTROGENA<sup>®</sup> Norská receptura
                </div>
                <div className="r2">Výživný krém na ruce Nordic Berry</div>
                <ul>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Podrobnější informace:
                    </span>
                    <br />
                    určeno pro citlivou a&nbsp;suchou pokožku
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Objem:
                    </span>
                    <br />
                    75 ml
                  </li>
                </ul>
                <div className="kremdorak">
                  <img
                    src="/malinanordycka/img/kremdorak.png"
                    width="165"
                    height="260"
                    alt
                  />
                </div>
                <div id="like_14"></div>
              </div>
              <div className="pr2">
                <div
                  className="r1"
                  style={{
                    "font-weight": "bold"
                  }}
                >
                  NEUTROGENA<sup>®</sup> Norská receptura
                </div>
                <div className="r2">Výživný krém na nohy Nordic Berry</div>
                <ul>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Podrobnější informace:
                    </span>
                    <br />
                    určeno pro citlivou a&nbsp;suchou pokožku
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Objem:
                    </span>
                    <br />
                    100 ml
                  </li>
                </ul>
                <div className="kremdostop">
                  <img
                    src="/malinanordycka/img/kremdostop.png"
                    width="157"
                    height="260"
                    alt
                  />
                </div>
                <div id="like_15"></div>
              </div>
            </div>
          </div>
          <div id="pop3">
            <div className="bgprod-mini">
              <div className="closer" />
              <div
                className="pr0"
                style={{
                  "font-family": "arial",
                  "font-size": "15px",
                  "font-weight": "bold"
                }}
              >
                Péče o rty
              </div>
              <div className="popprev">
                <img
                  src="/malinanordycka/img/prev.png"
                  width="29"
                  height="57"
                  alt
                />
              </div>
              <div className="popnext">
                <img
                  src="/malinanordycka/img/next.png"
                  width="29"
                  height="57"
                  alt
                />
              </div>
              <div className="pr1">
                <div
                  className="r1"
                  style={{
                    "font-weight": "bold"
                  }}
                >
                  NEUTROGENA<sup>®</sup> Norská receptura
                </div>
                <div className="r2">Výživný balzám na rty Nordic Berry</div>
                <ul>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Podrobnější informace:
                    </span>
                    <br />
                    určeno pro suché rty{" "}
                  </li>
                  <li>
                    <span
                      style={{
                        "font-weight": "bold"
                      }}
                    >
                      Objem balení:
                    </span>
                    <br />
                    4,8 g
                  </li>
                </ul>
                <div className="szty">
                  <img
                    src="/malinanordycka/img/szty.png"
                    width="141"
                    height="260"
                    alt
                  />
                </div>
                <div id="like_16"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="foo">
          <div id="foot"></div>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\nvar _gaq = _gaq || [];\n_gaq.push(['_setAccount', 'UA-35072974-1']);\n_gaq.push(['_trackPageview']);\n(function() {\nvar ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;\nga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';\nvar s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);\n})();\n"
            }}
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\nvar _gaq = _gaq || [];\n_gaq.push(['_setAccount', 'UA-29029437-17']);\n_gaq.push(['_trackPageview']);\n(function() {\nvar ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;\nga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';\nvar s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);\n})();\n"
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
